import React from 'react';
import {Link} from 'gatsby';
import Background from '../../assets/images/services/ui-ux/top-bg-ui-ux.jpg';

require('./detailservices.css');

class UIUXDevelopment extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  shouldComponentUpdate() {
    // remove when no jquery is used
    return false;
  }

  render() {
    return (
      <>
        <div>
          <section
            className="service-individual-page content-section-header"
            style={{backgroundImage: `url(${Background})`}}
          >
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 justify-content-center align-items-center">
                  <h1 className="latoBlack text-left text-white heading">UI/UX</h1>
                  <p className="latoRegular text-left text-white description">
                    We ensure client satisfaction by following an end-to-end product testing process
                    to provide accelerated delivery of exceptional digital experiences.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="services-skills mt-5">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 ">
                  <div className="mb-4 ">
                    <h1 className="latoBlack text-left heading">Skill Set</h1>
                    <p className="latoRegular text-left description">
                      Our team is trained to solve problems and provide innovative solutions
                      tailored carefully to meet client needs.
                    </p>
                    <div className="row">
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/user-reasearch.svg')
                              .default
                          }
                          alt="User Reasearch"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          User
                          <br />
                          Research
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/information-architecture.svg')
                              .default
                          }
                          alt="2Information Architecture"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Information
                          <br />
                          Architecture
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/wireframing.svg')
                              .default
                          }
                          alt="Wireframing"
                        />
                        <p className="text-center latoRegular icon-descriptions">Wireframing</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/prototyping.svg')
                              .default
                          }
                          alt="Prototyping"
                        />
                        <p className="text-center latoRegular icon-descriptions">Prototyping</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/visual-design.svg')
                              .default
                          }
                          alt="Visual Design"
                        />
                        <p className="text-center latoRegular icon-descriptions">Visual Design</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/usability-testing.svg')
                              .default
                          }
                          alt="Usability Testing"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Usability
                          <br />
                          Testing
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/heuristic-review.svg')
                              .default
                          }
                          alt="Heuristic Review"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Heuristic
                          <br />
                          Review
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/ui-ux/skillset/production-support.svg')
                              .default
                          }
                          alt="Production Support"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Production
                          <br />
                          Support
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <h1 className="latoBlack text-left heading">Tool Set</h1>
                    <p className="latoRegular text-left description">
                      Our team craftily translates their concepts into wireframes and mockups to
                      visually illustrate their ideas and thoughts.
                    </p>
                    <div className="row">
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/adobe-photoshop.png')
                              .default
                          }
                          alt="Adobe Photoshop"
                        />
                        <p className="text-center latoRegular icon-descriptions">Adobe Photoshop</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/adobe-illustrator.png')
                              .default
                          }
                          alt="Adobe Illustrator"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Adobe Illustrator
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/adobe-xd.png')
                              .default
                          }
                          alt="Adobe Xd"
                        />
                        <p className="text-center latoRegular icon-descriptions">Adobe Xd</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/sketch.png').default
                          }
                          alt="Sketch"
                        />
                        <p className="text-center latoRegular icon-descriptions">Sketch</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/dframer.png')
                              .default
                          }
                          alt="DFramer"
                        />
                        <p className="text-center latoRegular icon-descriptions">Framer</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/moqups.png').default
                          }
                          alt="Moqups"
                        />
                        <p className="text-center latoRegular icon-descriptions">Moqups</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/balsamiq.png')
                              .default
                          }
                          alt="Spine"
                        />
                        <p className="text-center latoRegular icon-descriptions">Balsamiq</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/ui-ux/toolset/invision.png')
                              .default
                          }
                          alt="Invision"
                        />
                        <p className="text-center latoRegular icon-descriptions">Invision</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 mb-5">
                    <h1 className="latoBlack text-left heading-portfolio">Portfolio</h1>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="box w-100 mb-0 text-left">
                          <img
                            className="img-fluid"
                            src={
                              require('../../assets/images/services/projects/decorist-service.png')
                                .default
                            }
                            alt="Decorist-Service"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <Link to="/portfolio/decorist-room-builder">
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/website-link.png').default
                                  }
                                />
                                <p className="description text-white text-center">Case study</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  <div class="col-lg-4 ">
                            <div class="box w-100">
                                 <img class="img-fluid" src={require('../../assets/images/services/projects/contact-journal-service.png') !!}"  alt="contact-journal-service" />
                                <div class="overlay d-flex">
                                    <div class="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                                        <a target="_blank" rel="noopener noreferrer"  href="https://itunes.apple.com/app/apple-store/id327685977?mt=8">
                                            <img class="img-fluid" src="{!!asset('images/services/app-store.png')!!}">
                                            <p class="description text-white text-center">
                                                App store
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                      {/*  <div class="col-lg-4">
                            <img class="img-fluid" src="{!!asset('images/services/ui-ux/eyesync1-service1.png')!!}" alt="eyesync1-service">
                        </div> */}
                      {/*  <div class="col-lg-4 col-md-4 pr-2 mb-4">
                            <img class="img-fluid" src="{!!asset('images/services/ui-ux/nimble1-service.png')!!}" alt="nimble1-service">
                            <p class="text-left latoBold projects">Nimble RX</p>
                        </div>
                        <div class="col-lg-4 col-md-4 pr-2 mb-4">
                            <img class="img-fluid" src="{!!asset('images/services/ui-ux/parent-village-service.png')!!}" alt="parent-village-service">
                            <p class="text-left latoBold projects">Parent Village</p>
                        </div>
                        <div class="col-lg-4 col-md-4 pr-2 mb-4">
                            <img class="img-fluid" src="{!!asset('images/services/ui-ux/sports-service.png')!!}" alt="sports-service">
                            <p class="text-left latoBold projects">Sports</p>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default UIUXDevelopment;
